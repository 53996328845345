import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import LoginReducer from "../../pages/Admin/LoginSlice";

const store = configureStore({
  reducer: {
    Login: LoginReducer,
    // Reducers
  },
});

export default store;
