import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Slide1Image1 from "../../../../used/image/slide 1-1.jpg";
import Slide1Image2 from "../../../../used/image/slide 1-2.jpg";

const Slide1Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2023-12-11 03:19:13                                  </div><br/>
  <h4 className="h4-md mb-3 fw-7 txt-blue">“Авлига ашиг сонирхлоос ангид байх” цуврал сургалт зохион байгууллаа</h4>
  <p align="justify" /><div className dir="auto">
    <div id=":r2ie:" className="x1iorvi4 x1pi30zi x1l90r2v x1swvt13" data-ad-comet-preview="message" data-ad-preview="message">
      <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
        <div className="xu06os2 x1ok221b">
          <div className="xdj266r x11i5rnm xat24cr x1mh8g0r x1vvkbs x126k92a">
            <div dir="auto" style={{textAlign: 'start'}}>
              <div className dir="auto">
                <div id=":r2k6:" className="x1iorvi4 x1pi30zi x1l90r2v x1swvt13" data-ad-comet-preview="message" data-ad-preview="message">
                  <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
                    <div className="xu06os2 x1ok221b">
                      <div className="xdj266r x11i5rnm xat24cr x1mh8g0r x1vvkbs x126k92a">
                        <div dir="auto" style={{textAlign: 'start'}}>“Автотээврийн үндэсний төв” ТӨҮГ-аас Авлигатай тэмцэх газрын Соён гэгээрүүлэх, урьдчилан сэргийлэх хэлтэстэй хамтран 2023 оны 10-р сарын 26, <span className="x193iq5w xeuugli x13faqbe x1vvkbs x1xmvt09 x1lliihq x1s928wv xhkezso x1gmr53x x1cpjm7i x1fgarty x1943h6x xudqn12 x3x7a5m x6prxxf xvq8zen xo1l8bm xzsf02u x1yc453h" dir="auto">11-р сарын 02</span>-ны өдрүүдэд зохион байгуулсан “Авлига ашиг сонирхлоос ангид байх” цуврал сургалтад нийслэл болон орон нутаг дах инженер, техникийн ажилчид, мэргэшсэн жолоочийн тасгийн мэргэжилтнүүд, автотээврийн улсын байцаагч нар <span className="x193iq5w xeuugli x13faqbe x1vvkbs x1xmvt09 x1lliihq x1s928wv xhkezso x1gmr53x x1cpjm7i x1fgarty x1943h6x xudqn12 x3x7a5m x6prxxf xvq8zen xo1l8bm xzsf02u x1yc453h" dir="auto">гээд 160 гаруй албан хаагчид хамрагдав.</span></div>
                        <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
                        <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
                        <div dir="auto" style={{textAlign: 'start'}}><img src={Slide1Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
                      </div>
                      <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                        <div dir="auto" style={{textAlign: 'start'}}>Тус сургалтаар “Авлига тэмцэх газрын танилцуулга”, “Авлигын хор хөнөөл, ангилал”, “Ашиг сонирхлын зөрчлөөс урьдчилан сэргийлэх”, “Авлигын эсрэг үйл ажиллагааны төлөвлөгөө, түүний хэрэгжилт”, “Авлига, ашиг сонирхлын зөрчлөөс урьдчилан сэргийлэх нь”, “Авлигын гэмт хэрэг, түүнээс урьдчилан сэргийлэх” зэрэг сэдвээр сургалт мэдээлэл өгөв.</div>
                        <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
                        <div dir="auto" style={{textAlign: 'start'}}><img src={Slide1Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
                        <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
                        <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
                      </div>
                      <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                        <div dir="auto" style={{textAlign: 'start'}}>Энэхүү сургалт нь төрийн байгууллагуудын “Авлигын эсрэг үйл ажиллагааны төлөвлөгөө”-нд заасан арга хэмжээний хэрэгжилтийг хангах, мэргэжил арга зүйн туслалцаа, зөвлөмж, кейс, жишээнд тулгуурлан харилцан санал солилцсон үр дүнтэй ажил боллоо.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
            <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
            <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <p>&nbsp;</p>
  <p>&nbsp;</p><p />
</div>

        </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide1Page;
