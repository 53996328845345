import React, { useState } from "react";
import axios from '../../axios';
import "./style.css";

const Form = () => {
  const [formData, setFormData] = useState({
    customer_name: "",
    email: "",
    phone_number: "",
    category: "",
    note: ""
  });
  const [token] = useState(localStorage.getItem("token"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      id: 0,
      type_id: 2,
      ...formData,
      is_active: 1
    };
    try {
      const response = await axios.post('/grievance/edit', dataToSend, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      console.log(response.data);
      alert('Таны санал хүсэлтийг хүлээн авлаа.');
      setFormData({
        customer_name: "",
        email: "",
        phone_number: "",
        category: "",
        note: ""
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div className="form-wrap">
      <h6>САНАЛ ХҮСЭЛТ ИЛГЭЭХ</h6>
      <form onSubmit={handleSubmit}>
        <div className="form-add">
          <div className="form-group">
            <input className="form-control"
              type="text"
              name="customer_name"
              value={formData.customer_name}
              onChange={handleChange}
              placeholder="Таны нэр..."
            />
          </div>
          <div className="form-group">
            <input className="form-control"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Имэйл хаяг..."
            />
          </div> 
          <div className="form-group">
            <input className="form-control"
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Утасны дугаар..."
            />
          </div>
        </div>
        <div className="form-group">
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">ХҮСЭЛТ</option>
            <option value="ӨРГӨДӨЛ">ӨРГӨДӨЛ</option>
            <option value="ГОМДОЛ">ГОМДОЛ</option>
          </select>
        </div>
        <div className="form-group">
          <textarea
            name="note"
            value={formData.note}
            onChange={handleChange}
            placeholder="Дэлгэрэнгүй мэдээллээ бичнэ үү..."
          />
        </div>
        <button type="submit" className="btn btn-light">
          ИЛГЭЭХ
        </button>
      </form>
    </div>
  );
};

export default Form;
