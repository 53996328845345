import React from 'react';
import "./style.css";
import Sup from "../../used/image/customer-support.png"
import Thumbs1 from "../../used/image/medee1.png"
import Thumbs2 from "../../used/image/medee2.png"
import Thumbs3 from "../../used/image/medee3.jpg"

function WidgetContact () {
  return (
    <div className="widget-contact">
      <div className="widget-wrap">
      
  <h6 className="h3-md fw-7 mb-4">Сүүлд нийтлэгдсэн мэдээ</h6>
  <div className="blog-list-footer">
    <ul className="list-unstyled">
      <li>
      <a href="/1005">
        <div className="post-media">
        <div className="media-body post-text">&nbsp;&nbsp;&nbsp;<img width={120} height={100} src={Thumbs1} alt ="medee" />
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2024-06-14</strong> </div>
            <h6 className="mb-3 h5-md">УЛС, ХОТ ХООРОНДЫН СТАТИСТИК ТООН МЭДЭЭ</h6>
        </div>
        </a>
      </li>
      <li>
      <a href="/1006">
        <div className="post-media">
        <div className="media-body post-text">&nbsp;&nbsp;&nbsp;<img width={120} height={100} src={Thumbs2} alt ="medee" />
        <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2024-06-07</strong> </div>	
            <h6 className="mb-3 h5-md">АВТОТЭЭВРИЙН ҮНДЭСНИЙ ТӨВ</h6>                                   
        </div>
        </a>
      </li>
          </ul>
  </div>
</div>


      <img className='widget-image' src={Sup} alt="Contact" style={{ maxWidth: '360px' }}/>
      <div className='widget-text'>
      <p>
      Тусламж хэрэгтэй юу?<br />
Таньд бидний үйлчилгээний тухай асуулт байвал доорхи утсанд холбогдоно уу ...
      </p>
      <button className="btn btn-warning">1900-1234</button>
      </div>
      <div>
      <h6 className="web-items-title">
        Хэрэгцээт Вэб сайтууд
      </h6>
    </div>
      <div className="web-items">
        <div className="item">
          <a href="https://mongolia.gov.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client1.png" alt="" /></a>
        </div>
        <div className="item">
          <a href="http://www.parliament.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client2.png" alt="" /></a>
        </div>
        <div className="item">
          <a href="https://mrtd.gov.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client3.png" alt="" /></a>
        </div>
        <div className="item">
          <a href="https://www.iaac.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client4.png" alt="" /></a>
        </div>
        <div className="item">
          <a href="http://www.rtdc.gov.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client5.png" alt="" /></a>
        </div>
        <div className="item">
          <a href="http://www.traffic-institute.mn/" target="_blank" rel="noreferrer"><img src="https://www.transdep.mn/img/links/client6.png" alt="" /></a>
        </div>
      </div>
    </div>
  );
}

export default WidgetContact ;
