import React from "react";
import "./style.css";
import ProgramsImage1 from "../../used/image/ticket.png";
import ProgramsImage2 from "../../used/image/cars.jpg";
import ProgramsImage3 from "../../used/image/transport-system.png";
import ProgramsImage4 from "../../used/image/logistics.jpg";
import ProgramsImage5 from "../../used/image/duudlaga-hudaldaa.jpeg";
import ProgramsImage6 from "../../used/image/propic6.jpg";

const Online = () => (
  <div className="online">
    <div className="online-container">
      <div className="itemon">
        <a
          href="https://eticket.transdep.mn"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage1} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">Автобус билет захиалга</h6>

            <p className="onsum">
              Улс, хот, аймаг, сум хоорондын зорчигч тээврийн билетийг
              интернэтээр захиалах онлайн програм
            </p>
          </div>
        </a>
      </div>
      <div className="itemon">
        <a
          href="https://www.autobox.mn"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage2} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">Авто үйлчилгээ</h6>

            <p className="onsum">
              Авто үйлчилгээний байгууллагуудыг нэгдсэн нэг системд оруулж,
              стандартад нийцсэн, үйлчилгээг иргэдэд үзүүлэх
            </p>
          </div>
        </a>
      </div>
      <div className="itemon">
        <a
          href="https://teever.transdep.mn/login"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage3} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">УЛС ХООРОНДЫН АЧАА ТЭЭВЭР</h6>

            <p className="onsum">
              Улс, хот хооронд, орон нутгийн зорчигч ачаа тээвэрлэлтийг зохион
              байгуулах, бүртгэх үйл ажиллагаа
            </p>
          </div>
        </a>
      </div>
      <div className="itemon">
        <a
          href="https://logistic.transdep.mn"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage4} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">Дотоодын ачаа тээвэр</h6>

            <p className="onsum">
              Тээвэрлэлтийн үйлчилгээг шуурхай, аюулгүй, найдвартай, даатгалтай,
              хяналттай байх боломжийг та бүхэнд олгож байна
            </p>
          </div>
        </a>
      </div>
      <div className="itemon">
        <a
          href="https://auction.transdep.mn"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage5} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">Дуудлага худалдаа</h6>

            <p className="onsum">
              Тээврийн хэрэгслийн улсын дугаарын дуудлага худалдааны цахим
              системийг хэрэглээнд нэвтрүүлээд байна
            </p>
          </div>
        </a>
      </div>
      <div className="itemon">
        <a
          href="https://burtgel.transdep.mn"
          className="news-title link-dark text-decoration-none"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={ProgramsImage6} alt="zz" />
          </div>
          <div>
            <h6 className="ontitle">ТХ-ийн дугаар захиалах</h6>

            <p className="onsum">
              Тээврийн хэрэгслийн улсын дугаараа сонгон захиалж 24 цаг дотор
              өөрт ойрхон салбараас авах боломжтой
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default Online;
