// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language {
  display: flex;
  justify-content: end;
  margin: 0 30px;
}

.language a span {
  position: absolute;
  color: #212529;
  padding-left: 3px;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Language/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".language {\n  display: flex;\n  justify-content: end;\n  margin: 0 30px;\n}\n\n.language a span {\n  position: absolute;\n  color: #212529;\n  padding-left: 3px;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
