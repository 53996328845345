import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import axios from "../../../axios";
import { setLoading, setError, setUserToken } from "../LoginSlice";
import "./style.css";

const Login = ({ setIsLogged }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const tokenResponse = await axios.get('user/login');
      const { token } = tokenResponse.data;
      localStorage.setItem('token', token);
      console.log('guest token saved:', token);
      dispatch(setUserToken(token));

      const loginCredentials = {
        email,
        password,
      };

      const loginResponse = await axios.post(
        "user/login",
        loginCredentials,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const { token: loginToken, role } = loginResponse.data;
      dispatch(setUserToken(loginToken));
      localStorage.setItem("token", loginToken);
      console.log('Logged in:', role);
      console.log('Token', loginResponse.data);

      
      setIsLogged(false);

      if (role === 'admin') {  
        navigate('/admin');
      } else {
        navigate('/'); 
      }

    } catch (error) {
      console.error('Error logging in:', error);
      if (error.response && error.response.data && error.response.data.message) {
        dispatch(setError(error.response.data.message));
      } else {
        dispatch(setError('An unexpected error occurred.'));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>Password:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
