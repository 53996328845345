import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./style.css";

const Editor = ({ itemId }) => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [editorData, setEditorData] = useState("");
  const [id, setId] = useState(null);

  const fetchData = useCallback(async (itemId) => {
    try {
      const response = await axios.post(
        "article/data",
        { id: itemId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedData = response.data.data;
      setData(fetchedData);
      setTitle(fetchedData.title);
      setEditorData(fetchedData.content);
      setId(fetchedData.id);
    } catch (error) {
      setError(error);
    }
  }, [token]);

  const handleSave = async () => {
    try {
      await axios.post(
        "article/edit",
        {
          id: id,
          title: title,
          content: editorData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Амжилттай засагдлаа.");
    } catch (error) {
      console.error("Алдаатай байна.", error);
      alert("Алдаатай байна.");
    }
  };

  useEffect(() => {
    if (itemId !== null) {
      fetchData(itemId);
    }
  }, [itemId, fetchData]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data) {
    return <div className="text-center">Цэсээ сонгоорой.</div>;
  } else {
    return (
      <div className="editor">
        <input
          type="text"
          className="post-title-input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
        />
        <button onClick={handleSave} className="save-button">
          Save
        </button>
      </div>
    );
  }
};

export default Editor;
