import React, { useEffect } from 'react';

const Weather = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app2.weatherwidget.org/js/?id=ww_799cae0433ab9';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='weather' style={{ height: '60px' }}>
    <div id="ww_799cae0433ab9" v='1.3' loc='auto' a={"{\"t\":\"horizontal\",\"lang\":\"en\",\"sl_lpl\":1,\"ids\":[],\"font\":\"Arial\",\"sl_ics\":\"one_a\",\"sl_sot\":\"celsius\",\"cl_bkg\":\"image\",\"cl_font\":\"#FFFFFF\",\"cl_cloud\":\"#FFFFFF\",\"cl_persp\":\"#81D4FA\",\"cl_sun\":\"#FFC107\",\"cl_moon\":\"#FFC107\",\"cl_thund\":\"#FF5722\"}"} style={{ height: '100%', width: '80%' }}>
      <a href="https://weatherwidget.org/" id="ww_799cae0433ab9_u" target="_blank" rel="noopener noreferrer">
        HTML Weather widgets
      </a>
    </div>
    </div>
  );
};

export default Weather;
