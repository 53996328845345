// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  height: 60px;
  width: 100%;
  background-color: rgba(10, 36, 88, 0.9);
  padding: 20px 20px;
  box-sizing: border-box;
  z-index: 90;
  color: #ffffff;
}

.footer-text a {
  color: #fff;
}

.footer-text a:hover {
  color: #ffb000;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,uCAAuC;EACvC,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".footer-container {\n  height: 60px;\n  width: 100%;\n  background-color: rgba(10, 36, 88, 0.9);\n  padding: 20px 20px;\n  box-sizing: border-box;\n  z-index: 90;\n  color: #ffffff;\n}\n\n.footer-text a {\n  color: #fff;\n}\n\n.footer-text a:hover {\n  color: #ffb000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
