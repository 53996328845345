import React from 'react';

const AdminSearch = ({ searchQuery, onSearchChange }) => {
  return (
    <div className="">
      <label htmlFor="searchInput" className="form-label">
      </label>
      <input
        type="text"
        className="form-control"
        id="searchInput"
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
};

export default AdminSearch;