import React from "react";
import "./style.css";
import newsImage1 from "../../used/image/medee1.png";
import newsImage2 from "../../used/image/medee2.png";
import newsImage3 from "../../used/image/medee3.jpg";
import axios from "../../axios";

const newsData = () => {
  const postData = {
    ppdate: "2024-03-28",
    pptitle: "Техникийн хяналтын үзлэгийн статистик мэдээ",
    ppsum:
      "2024 оны 01 дүгээр сарын 02-с 03 дугаар сарын 25-ний өдрийн байдлаар улсын хэмжээнд 191643 тээврийн хэрэгсэл техникийн хяналтын үзлэгт хамрагдасан байна. Үүнээс АТҮТ ТӨҮГ-ийн харьяа техникийн хяналтын үзлэгийн төвүүдэд 138034 тээврийн хэрэгсэл техникийн хяналтын үзлэгт хамрагдсан. Хувийн хэвшлийн үзлэгийн төвүүдэд 53609 тээврийн хэрэгсэл техникийн хяналтын үзлэгт хамрагдсан.",
  };
};

const News = () => (
  <div className="news">
    <div className="news-block"> 
    <h6><strong>МЭДЭЭ МЭДЭЭЛЭЛ</strong></h6>
    <h6><a href="/1000"><strong>БҮX МЭДЭЭГ ХАРАХ</strong></a></h6>
    </div>
    <div className="news-container">
      <div className="itemb1">
      <a
              href="/90"
              className="news-link link-dark text-decoration-none"
            >
        <div>
          <img src={newsImage1} alt="zz" />
        </div>
        <div>
          <div className="news-text">
            <p className="pdate">2024-06-14</p>
            <h6 className="ptitle">Улс, хот хоорондын статистик тоон мэдээ</h6>

            <p className="psum">
            Хот хоорондын мэдээ:<br/>
            Хот хоорондын нийтийн тээврийн үйлчилгээгээр 2024.01.01 – 2024.06.01-ний өдрийн байдлаар 594,248 зорчигч тээвэрлэсэн байна.<br/>
            Улс хоорондын мэдээ:<br/>
            2024.01.01 – 2024.06.01-ний өдрийн байдлаар улс хоорондын зорчигчдын тогтмол тээврийн хэрэгслээр 182,386 зорчигч тээвэрлэсэн байна.
            </p>
              <p className="plink">
                Цааш унших{" "}
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </p>
          </div>
        </div>
        </a>
      </div>

      <div className="itemb2">
      <a
              href="/91"
              className="news-link link-dark text-decoration-none"
            >
        <div>
          <img src={newsImage2} alt="zz" />
        </div>
        <div>
          <div className="news-text">
            <p className="pdate">2024-06-07</p>
            <h6 className="ptitle">
            Автотээврийн үндэсний төв
            </h6>
            <p className="psum">
            Автотээврийн үндэсний төв нь 2023 онд нийт 6,830,213,687 төгрөгийн татвар төлсөн байна.
            </p>
              <p className="plink">
                Цааш унших{" "}
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </p>
          </div>
        </div>
        </a>
      </div>
      <div className="itemb3">
      <a
              href="/92"
              className="news-link link-dark text-decoration-none"
            >
        <div>
          <img src={newsImage3} alt="zz" />
        </div>
        <div>
          <div className="news-text">
            <p className="pdate">2024-06-06</p>
            <h6 className="ptitle">
            ИРГЭДИЙН АНХААРАЛД
            </h6>
            <p className="psum">
            Хууль бус тээвэрлэлт гүйцэтгэж буй тээврийн хэрэгслийн жолооч нар нь хяналтгүй тээвэрлэлт гүйцэтгэхдээ “Автотээврийн хэрэгслээр ачаа, зорчигч тээвэрлэх дүрэм”-ийг зөрчин зөвшөөрөгдсөн даацын хэмжээг хэтрүүлэх, орон нутгийн замд хурд хэтрүүлэх, техникийн хяналтын үзлэгт ороогүй тээврийн хэрэгслээр үйлчилгээнд гарах зэрэг олон зөрчил гаргаж байгаа юм. Энэ нь орон нутгийн замд гарч байгаа осол зөрчлийг ихээхэн нэмэгдүүлж байна.
            </p>
              <p className="plink">
                Цааш унших{" "}
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </p>
          </div>
        </div>
        </a>
      </div>
    </div>
  </div>
);

export default News;
