import axios from "axios";

const instane = axios.create({
  // baseURL: "http://localhost:5002/api/v1",
  baseURL: "https://service.transdep.mn/autobox-backend/api/v1/",
});

// const token = localStorage.getItem("token");
// instane.defaults.headers.common = {
//   Authorization: `Bearer ${token}`,
// };

export default instane;

