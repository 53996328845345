import React from "react";
import "./style.css";
import Form from "../Form";

const Contact = () => {
  return (
    <div className="contact">
      <div className="container">
      <div className="contact-container">
        <div id="sanal" className="contact-form">
          <Form />
        </div>
        <div className="contact-map">
          <h6>Байршил, газрын зураг</h6>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1891.6254176115004!2d106.87922557551335!3d47.894406625120915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d969250317f7d35%3A0xc51169d3c437ea3f!2zItCQ0LLRgtC-0YLRjdGN0LLRgNC40LnQvSDSrtC90LTRjdGB0L3QuNC5INCi06nQsiIg0KLTqNKu0JM!5e0!3m2!1sen!2smn!4v1709016996446!5m2!1sen!2smn"
            style={{ border: "1px solid #ffffff" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          />
        </div>
        </div>
        </div>
    </div>
  );
};

export default Contact;
