import React from "react";
import "./style.css";

export const Tabs = () => {
  return (
    <div className="tabs-main">
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <div
            className="tabs-link nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="a"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            ТЕНДЕРИЙН УРИЛГА
          </div>
        </li>
        <li className="nav-item" role="presentation">
          <div
            className="tabs-link nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="a"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            ОЛГОСОН ЗӨВШӨӨРӨЛ
          </div>
        </li>
        <li className="nav-item" role="presentation">
          <div
            className="tabs-link nav-link"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-contact"
            type="a"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
          >
            АЖЛЫН БАЙРНЫ ЗАР
          </div>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div className="card-container">
            <div className="tender-card">
              <h6>ТЕНДЕРИЙН УРИЛГА - 2023.09.25</h6>
              <p>“Тээвэрчдийн ордон” гадна талбайн зураг, төсөв</p>
              <a href="/1009">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
            <div className="tender-card">
              <h6>ТЕНДЕРИЙН УРИЛГА - 2023.05.15</h6>
              <p>Эд хөрөнгийн даатгалын үйлчилгээ</p>
              <a href="/1010">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
            <div className="tender-card">
              <h6>ТЕНДЕРИЙН УРИЛГА - 2023.05.04</h6>
              <p>
                Техникийн хяналтын үзлэгийн тоног төхөөрөмж,
                <br /> багаж, сэлбэг хэрэгсэл
              </p>
              <a href="/1011">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabindex="0"
        >
          <div className="accept-card">
            <p>
              2023 оны III-р улиралд олгосон хил нэвтрэх С төрлийн зорчих
              зөвшөөрлийн бичгийн зарцуулалт
            </p>
            <a href="/1012">
              <p className="plink link-dark">
                Цааш унших{" "}
                <svg
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </p>
            </a>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
          tabindex="0"
        >
          <div className="card-container">
            <div className="tender-card">
              <h6>АЖЛЫН БАЙР</h6>
              <p>2024-07-30</p>
              <p>Автотээврийн хяналтын мэргэжилтэн</p>
              <a href="/1013">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
            <div className="tender-card">
              <h6>АЖЛЫН БАЙР</h6>
              <p>2024-07-30</p>
              <p>
              Тээвэр зохицуулагч
              </p>
              <a href="/1014">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
            <div className="tender-card">
              <h6>АЖЛЫН БАЙР</h6>
              <p>2024-07-30</p>
              <p>Дотоод ажил, аж ахуй хариуцсан мэргэжилтэн</p>
              <a href="/1015">
                <p className="plink link-dark">
                  Цааш унших{" "}
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </p>
              </a>
            </div>
          </div>
          <a className="tabsnext-link" href="/77">
            <h6>БҮХ АЖЛЫН БАЙР ХАРАХ</h6>
          </a>
        </div>
      </div>
    </div>
  );
};
