// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.taniltsuulga {
    display: flex;
    text-align: justify;
    align-items: center;
    justify-content: center;
   
    margin: 60px 140px 60px 140px;
    font-size: 15px;
}

.post-container {
    max-width: 1400px;
    display: flex;
    

}

.post-title {
    padding-left: 20px;
    color: #fff;
    background: rgba(10, 36, 88, 0.99);
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.post-content {
    padding: 30px;
    border-right: 1px solid #ddd;
}

td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
 tr:nth-child(odd) {
    background-color: #dce7fc;
  }
  
 tr:hover {
    background-color: #0A2458;
    color: #ffffff;
  }`, "",{"version":3,"sources":["webpack://./src/pages/Batalgaajuulalt/AchaaTeever/AchaaUnelgee/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;;IAEvB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,aAAa;;;AAGjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,kCAAkC;IAClC,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,YAAY;EACd;;CAED;IACG,yBAAyB;EAC3B;;CAED;IACG,yBAAyB;IACzB,cAAc;EAChB","sourcesContent":[".taniltsuulga {\n    display: flex;\n    text-align: justify;\n    align-items: center;\n    justify-content: center;\n   \n    margin: 60px 140px 60px 140px;\n    font-size: 15px;\n}\n\n.post-container {\n    max-width: 1400px;\n    display: flex;\n    \n\n}\n\n.post-title {\n    padding-left: 20px;\n    color: #fff;\n    background: rgba(10, 36, 88, 0.99);\n    height: 45px;\n    display: flex;\n    align-items: center;\n    font-size: 15px;\n}\n\n.post-content {\n    padding: 30px;\n    border-right: 1px solid #ddd;\n}\n\ntd {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n tr:nth-child(odd) {\n    background-color: #dce7fc;\n  }\n  \n tr:hover {\n    background-color: #0A2458;\n    color: #ffffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
