// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  letter-spacing: normal;
}

.App h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.1;
}

.App a {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/style.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;;;;;;EAME,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".App {\n  font-family: \"Roboto Condensed\", sans-serif;\n  font-weight: 300;\n  letter-spacing: normal;\n}\n\n.App h1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  text-transform: uppercase;\n  font-weight: 300;\n  line-height: 1.1;\n}\n\n.App a {\n  text-decoration: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.container {\n  max-width: 1200px;\n  margin: 0 auto;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
