import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Slide3Image1 from "../../../../used/image/slide 3-1.jpg";
import Slide3Image2 from "../../../../used/image/slide 3-2.jpg";

const Slide3Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2023-12-11 03:08:12</div><br/>
  <h4 className="h4-md mb-3 fw-7 txt-blue">Автотээврийн үндэсний төвийн дэргэдэх Эмэгтэйчүүдийн холбооноос эмэгтэй албан хаагчдадаа зориулж сургалт зохион байгууллаа</h4>
  <p align="justify" /><div className dir="auto">
    <div id=":r2ie:" className="x1iorvi4 x1pi30zi x1l90r2v x1swvt13" data-ad-comet-preview="message" data-ad-preview="message">
      <div className="x78zum5 xdt5ytf xz62fqu x16ldp7u">
        <div className="xu06os2 x1ok221b">
          <div className="xdj266r x11i5rnm xat24cr x1mh8g0r x1vvkbs x126k92a">
            <div dir="auto" style={{textAlign: 'start'}}>Автотээврийн үндэсний төвийн дэргэдэх Эмэгтэйчүүдийн холбооноос эмэгтэй албан хаагчдадаа зориулж “Ажил мэргэжилтэй уялдсан стиль бүрдүүлэлтийн зөвлөгөө”, “Эмэгтэйчүүдийн манлайлал, амжилтын үндэс” сэдвээр сургалт зохион байгууллаа</div>
            <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
            <div dir="auto" style={{textAlign: 'start'}}><img src={Slide3Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
            <div dir="auto" style={{textAlign: 'start'}}>&nbsp;</div>
          </div>
          <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
            <div dir="auto" style={{textAlign: 'start'}}>Сургалтаар “Gerel Styling Studio” төвийн Багш Л.Цолмонгэрэл “Биеийн галбирт тохируулж хувцаслах”, “Албан хувцаслалт”, МУ-ын гавьяат багш ЭЗ ШУ доктор профессор Ц.Долгорсүрэн “Хувь хүний манлайлал” “Стресс менежмент” зэрэг сэдвээр сургалт мэдээлэл өгөв.</div>
          </div>
          <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
            <div dir="auto" style={{textAlign: 'start'}}>Зөвлөмж, кейс, жишээнд тулгуурлан харилцан санал солилцсон үр дүнтэй сургалт боллоо.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>&nbsp;</p>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;<img src={Slide3Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">&nbsp;</div>
  <p>&nbsp;</p>
  <p>&nbsp;</p><p />
</div>

</div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide3Page;
