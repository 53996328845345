// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service {
  max-height: 600px;
  background: rgba(242, 242, 242, 1);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.service-link {
  color: #0a2458;
  font-size: 15px;
}

.service-link:hover {
  color: #2186d9;
}

.service-image img {
  max-height: 600px;
  width: 730px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
}

/* Adjust max-width of the contained image based on viewport size */

@media (max-width: 1201px) {
  .service-image img {
    display:flex;
    height: 400px;
    width: 500px;
  }
  .service {
    justify-content: end;
    height: 400px;
  }
}

@media (max-width: 1200px) and (max-width: 1001px) {
  .service-image img {
    width: 400px;
  }
  .service {
    font-size: 15px;
  }
}

@media (max-width: 800px) {
  .service-image img {
    display: none;
  }
  .service {
    height: 500px;
    padding: 20px;
    font-size: 15px;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Service/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,wCAAwC;AAC1C;;AAEA,mEAAmE;;AAEnE;EACE;IACE,YAAY;IACZ,aAAa;IACb,YAAY;EACd;EACA;IACE,oBAAoB;IACpB,aAAa;EACf;AACF;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,aAAa;IACb,aAAa;IACb,eAAe;IACf,uBAAuB;EACzB;AACF","sourcesContent":[".service {\n  max-height: 600px;\n  background: rgba(242, 242, 242, 1);\n  display: flex;\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  font-size: 15px;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);\n}\n\n.service-link {\n  color: #0a2458;\n  font-size: 15px;\n}\n\n.service-link:hover {\n  color: #2186d9;\n}\n\n.service-image img {\n  max-height: 600px;\n  width: 730px;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);\n}\n\n/* Adjust max-width of the contained image based on viewport size */\n\n@media (max-width: 1201px) {\n  .service-image img {\n    display:flex;\n    height: 400px;\n    width: 500px;\n  }\n  .service {\n    justify-content: end;\n    height: 400px;\n  }\n}\n\n@media (max-width: 1200px) and (max-width: 1001px) {\n  .service-image img {\n    width: 400px;\n  }\n  .service {\n    font-size: 15px;\n  }\n}\n\n@media (max-width: 800px) {\n  .service-image img {\n    display: none;\n  }\n  .service {\n    height: 500px;\n    padding: 20px;\n    font-size: 15px;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
