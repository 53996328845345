import React, { useEffect, useState } from "react";
import "./style.css";
import SlideImage1 from "../../used/image/newsimage11.jpg";
import SlideImage2 from "../../used/image/slide 2-1.jpg";
import SlideImage3 from "../../used/image/newsimage12.jpg";
import Heseg1 from "../../used/image/heseg1.jpg";
import Heseg2 from "../../used/image/heseg2.jpg";

const slides = [
  { src: SlideImage1 },
  { src: SlideImage2 },
  { src: SlideImage3 },
];

const Slide = ({ interval = 6000 }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    console.log("Current Slide Index:", currentSlideIndex);
    const slideInterval = setInterval(() => {
      setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, interval);

    return () => clearInterval(slideInterval);
  }, [currentSlideIndex, interval]);

  const handleIndicatorClick = (index) => {
    setCurrentSlideIndex(index);
  };
  
  const titles = [
    "“Авлига ашиг сонирхлоос ангид байх” цуврал сургалт зохион байгууллаа",
    "Ажлын байран дахь хөдөлмөрийн аюулгүй байдал, эрүүл ахуйн дэлхийн өдөр",
    "Автотээврийн үндэсний төвийн дэргэдэх Эмэгтэйчүүдийн холбооноос эмэгтэй албан хаагчдадаа зориулж сургалт зохион байгууллаа",
  ];

  const paragraphs = [
    "“Автотээврийн үндэсний төв” ТӨҮГ-аас Авлигатай тэмцэх газрын Соён гэгээрүүлэх, урьдчилан сэргийлэх хэлтэстэй хамтран 2023 оны 10-р сарын 26, 11-р сарын 02-ны өдрүүдэд зохион байгуулсан “Авлига ашиг сонирхлоос ангид байх” цуврал сургалтад нийслэл болон орон нутаг дах инженер, техникийн ажилчид, мэргэшсэн жолоочийн тасгийн мэргэжилтнүүд, автотээврийн улсын байцаагч нар гээд 160 гаруй албан хаагчид хамрагдав.",
    "Автотээврийн үндэсний төвөөс нэн түрүүнд тээврийн аюулгүй байдал, эрүүл ахуйн соёлыг төлөвшүүлэх, сайн туршлагагүй нэвтрүүлэн солилцох, ажлын байран дахь осол гэмтлийг бууруулах, аюулгүй ажиллагааг хэвшүүлэх, бэртэл гэмтлээс урьдчилан сэргийлэх зорилгоор Дархан-Уул аймгийн Автотээврийн төвд энэхүү өдөрлөгийг зохион байгууллаа.",
    "Автотээврийн үндэсний төвийн дэргэдэх Эмэгтэйчүүдийн холбооноос эмэгтэй албан хаагчдадаа зориулж “Ажил мэргэжилтэй уялдсан стиль бүрдүүлэлтийн зөвлөгөө”, “Эмэгтэйчүүдийн манлайлал, амжилтын үндэс” сэдвээр сургалт зохион байгууллаа",
  ];

  const url = [
    "/1001",
    "/1002",
    "/1003",
  ];

  const renderMultilineText = (text) => {
    return text.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="slide">
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={
              currentSlideIndex === index
                ? "carousel-item active"
                : "carousel-item"
            }
          >
            <picture className="slide-images">
              <img className="heseg1" src={Heseg1} alt="" />
              <img className="heseg3" src={slide.src} alt={`Slide ${index + 1}`} />
              <img className="heseg2" src={Heseg2} alt="" />
            </picture>
            <div className="carousel-caption fadeInDown">
              <h5><strong>{renderMultilineText(titles[index])}</strong></h5>
              <p>{renderMultilineText(paragraphs[index])}</p>
              <a href={url[index]} className="btn btn-light btn-lg">
                ЦААШ УНШИХ
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            className={currentSlideIndex === index ? "active" : ""}
            onClick={() => handleIndicatorClick(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slide;
