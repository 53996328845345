import React from "react";
import "./style.css";

const Menu = () => {
  return (
    <div className="top-menu">
      <nav className="navbar navbar-expand-lg header-fullpage nav-light">
        <button
          className="navbar-toggler x collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
          <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="/"
              >
                Нүүр хуудас
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Бидний тухай{" "}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/8">
                    Танилцуулга
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/9">
                    Эрхэм зорилго
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/10">
                    Чиг үүрэг
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/11">
                    Удирдлагын баг
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/12">
                    Бүтэц зохион байгуулалт
                  </a>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Бүтцийн нэгжүүд{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/14" className="dropdown-item">
                        Судалгаа хөгжлийн чанарын удирдлагын төв
                      </a>
                    </li>
                    <li>
                      <a href="/15" className="dropdown-item">
                        Хяналт шинжилгээ дотоод аудитын алба
                      </a>
                    </li>
                    <li>
                      <a href="/16" className="dropdown-item">
                        Захиргаа сургалт хүний нөөцийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/17" className="dropdown-item">
                        Мэдээллийн технологийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/18" className="dropdown-item">
                        Санхүү эдийн засгийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/19" className="dropdown-item">
                        Тээврийн хэрэгслийн бүртгэлийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/20" className="dropdown-item">
                        Техникийн хяналтын хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/21" className="dropdown-item">
                        Зорчигч тээврийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/22" className="dropdown-item">
                        Ачаа тээврийн хэлтэс
                      </a>
                    </li>
                    <li>
                      <a href="/23" className="dropdown-item">
                        Хөдөлгөөний удирдлагын хэлтэс
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Хууль эрхзүй{" "}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/24">
                    Монгол улсын хууль
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/25">
                    Засгийн газрын тогтоол
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/26">
                    Сайдын тушаал
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/27">
                    Захирлын тушаал
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/28">
                    Дүрэм журам
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/29">
                    Стандарт
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/30">
                    Захиргааны хэм хэмжээний актууд
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/31">
                    Хууль, дүрэм, журмын жагсаалт
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="https://gia.gov.mn/45/all">
                  Төрийн болон албаны нууц
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Баталгаажуулалт{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Бидний тухай{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/32" className="dropdown-item">
                        Танилцуулга
                      </a>
                    </li>
                    <li>
                      <a href="/33" className="dropdown-item">
                        Чанарын бодлого
                      </a>
                    </li>
                    <li>
                      <a href="/34" className="dropdown-item">
                        Шударга байдлын мэдэгдэл
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Эрхзүйн мэдээлэл{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/13071"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Стандартчилал, техникийн зохицуулалт,
                        <br />
                        тохирлын үнэлгээний итгэмжлэлийн тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/102?lawid=102"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Байгууллагын нууцын тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/537"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Хувь хүний нууцын тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/294"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Иргэдээс төрийн байгууллага, албан тушаалтанд
                        <br />
                        гаргасан өргөдөл, гомдлыг шийдвэрлэх тухай хууль
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.legalinfo.mn/law/details/13707"
                        target="_blank"
                        rel="noreferrer"
                        className="dropdown-item"
                      >
                        Жагсаалт, журам шинэчлэн батлах тухай тогтоол
                      </a>
                    </li>
                    <li>
                      <a href="/35" className="dropdown-item">
                        Баталгаажуулалтын үйл явцын журам
                      </a>
                    </li>
                    <li>
                      <a href="/36" className="dropdown-item">
                        Баталгаажуулалтыг түдгэлзүүлэх, цуцлах,
                        <br />
                        хамрах хүрээг багасгах журам
                      </a>
                    </li>
                    <li>
                      <a href="/37" className="dropdown-item">
                        Баталгаажуулалтын гэрээ
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/38">
                    Үнэ тариф
                  </a>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Автотээврийн хэрэгслийн засвар
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/39" className="dropdown-item">
                        Өргөдлийн маягт
                      </a>
                    </li>
                    <li>
                      <a href="/40" className="dropdown-item">
                        Судалгааны маягт
                      </a>
                    </li>
                    <li>
                      <a href="/41" className="dropdown-item">
                        Өөрийгөө үнэлэх үнэлгээний хуудас
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Нийтийн зорчигч тээврийн
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/42" className="dropdown-item">
                        Өргөдлийн маягт
                      </a>
                    </li>
                    <li>
                      <a href="/43" className="dropdown-item">
                        Судалгааны маягт
                      </a>
                    </li>
                    <li>
                      <a href="/44" className="dropdown-item">
                        Өөрийгөө үнэлэх үнэлгээний хуудас
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Ачаа тээврийн
                    <br />
                    үйлчилгээний баталгаажуулалт{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/45" className="dropdown-item">
                        Өргөдлийн маягт
                      </a>
                    </li>
                    <li>
                      <a href="/46" className="dropdown-item">
                        Судалгааны маягт
                      </a>
                    </li>
                    <li>
                      <a href="/47" className="dropdown-item">
                        Өөрийгөө үнэлэх үнэлгээний хуудас
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/48">
                    Баталгаажсан үйлчилгээний жагсаалт
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Үйлчилгээ{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Цахим үйлчилгээ{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://eticket.transdep.mn/"
                      >
                        Автобусны Тасалбар захиалах
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.autobox.mn"
                      >
                        Авто үйлчилгээний ангилал тогтоолгох
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://teever.transdep.mn/login"
                      >
                        Тээврийн нэгдсэн систем
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://burtgel.transdep.mn"
                      >
                        Тээврийн хэрэгслийн дугаар захиалах
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://auction.transdep.mn"
                      >
                        Дугаарын дуудлага худалдаа
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://logistic.transdep.mn"
                      >
                        Дотоодын ачаа тээвэр
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://www.transdep.mn/epay"
                      >
                        Бүртгэлийн төлбөрийн програм
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Тээврийн хэрэгсэлд тавигдах шаардлага
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/51" className="dropdown-item">
                        Ачаа тээвэрт тавигдах шаардлага
                      </a>
                    </li>
                    <li>
                      <a href="/52" className="dropdown-item">
                        Жуулчин тээвэрт тавигдах шаардлага
                      </a>
                    </li>
                    <li>
                      <a href="/53" className="dropdown-item">
                        Зорчигч тээвэрт тавигдах шаардлага
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/54">
                    Тээврийн хэрэгслийг бүртгэлээс хасуулах
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/55">
                    Мэргэшсэн жолоочийн шалгалт
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/56">
                    Бүрдүүлэх баримт бичгийн жагсаалт
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ил тод байдал{" "}
              </a>
              <ul className="dropdown-menu">
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Үйл ажиллагаа{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/57" className="dropdown-item">
                        Техникийн хяналтын үзлэг
                      </a>
                    </li>
                    <li className="dropdown-submenu">
                      <a
                        href="#"
                        className="dropdown-item dropdown-submenu-toggle icon-left"
                      >
                        Тээвэр зохицуулалт{" "}
                        <svg
                          width="10"
                          height="10"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                          />
                        </svg>
                      </a>
                      <ul className="dropdown-menu dropdown-submenu">
                        <li>
                          <a href="/58" className="dropdown-item">
                            Танилцуулга
                          </a>
                        </li>
                        <li>
                          <a href="/59" className="dropdown-item">
                            Олгосон зөвшөөрлийн мэдээлэл
                          </a>
                        </li>
                        <li>
                          <a href="/60" className="dropdown-item">
                            Хяналт шалгалтын тайлан
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/61" className="dropdown-item">
                        Тээврийн удирдлага
                      </a>
                    </li>
                    <li>
                      <a href="/62" className="dropdown-item">
                        Тээврийн хэрэгслийн бүртгэл
                      </a>
                    </li>
                    <li>
                      <a href="/63" className="dropdown-item">
                        Авто үйлчилгээ
                      </a>
                    </li>
                    <li>
                      <a href="/64" className="dropdown-item">
                        Мэргэшсэн жолоочийн тасаг
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Худалдан авах ажиллагаа{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/65" className="dropdown-item">
                        2016
                      </a>
                    </li>
                    <li>
                      <a href="/66" className="dropdown-item">
                        2017
                      </a>
                    </li>
                    <li>
                      <a href="/67" className="dropdown-item">
                        2018
                      </a>
                    </li>
                    <li>
                      <a href="/68" className="dropdown-item">
                        2019
                      </a>
                    </li>
                    <li>
                      <a href="/69" className="dropdown-item">
                        2020
                      </a>
                    </li>
                    <li>
                      <a href="/70" className="dropdown-item">
                        2021
                      </a>
                    </li>
                    <li>
                      <a href="/71" className="dropdown-item">
                        2022
                      </a>
                    </li>
                    <li>
                      <a href="/72" className="dropdown-item">
                        2023
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/73">
                    Төсөв санхүү
                  </a>
                </li>
                <li className="dropdown-submenu">
                  <a
                    href="#"
                    className="dropdown-item dropdown-submenu-toggle icon-left"
                  >
                    Хүний нөөц{" "}
                    <svg
                      width="10"
                      height="10"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  </a>
                  <ul className="dropdown-menu dropdown-submenu">
                    <li>
                      <a href="/74" className="dropdown-item">
                        Хүний нөөцийн стратеги, бодлого
                      </a>
                    </li>
                    <li>
                      <a href="/75" className="dropdown-item">
                        Албан хаагчийн үйл ажиллагаа, үнэлгээ
                      </a>
                    </li>
                    <li>
                      <a href="/76" className="dropdown-item">
                        Албан хаагчийн ёсзүйн дүрэм, удирдамж
                      </a>
                    </li>
                    <li>
                      <a href="/77" className="dropdown-item">
                        Ажлын байр
                      </a>
                    </li>
                    <li>
                      <a href="/78" className="dropdown-item">
                        Орон тооны судалгаа
                      </a>
                    </li>
                    <li>
                      <a href="/79" className="dropdown-item">
                        Сэтгэл ханамжийн судалгааны тайлан
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a className="dropdown-item" href="/80">
                    Засгийн газар хоорондын хэлэлцээр
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/81">
                    Төсөл хөтөлбөр
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/82">
                    Тайлан
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/83">
                    Монгол бичгийн үндэсний хөтөлбөр
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/89">
                    Оюуны өмчийн эрхийн жагсаалт
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
                  <a 
                  className="nav-link d-none d-lg-inline-block align-self-center" 
                  href="/50"
                  >
                    Үнэ тариф
                  </a>
                </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="https://shilendans.gov.mn/organization/43185"
              >
                Шилэн данс
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link d-none d-lg-inline-block align-self-center"
                href="/1005"
              >
                ХОЛБОО БАРИХ
              </a>
            </li>
            
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Menu;
