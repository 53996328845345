import { combineReducers } from "redux";
import { createSlice } from "@reduxjs/toolkit";

const messagesReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      return [...state, action.payload];
    default:
      return state;
  }
};

export const LoginSlice = createSlice({
  name: "Login",
  initialState: {
    loading: false,
    error: null,
    token: null,
    poster: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setPoster: (state, action) => {
      state.poster = action.payload;
    },
  },
});

const rootReducer = combineReducers({
  messages: messagesReducer,
  Login: LoginSlice.reducer,
});

export const { setLoading, setError, setUserToken, setPoster } = LoginSlice.actions;

export { rootReducer };
