import React from "react";
import "./style.css";
import PictureService from "../../used/image/slide1.jpg";

function Service({ onLinkClick }) {
  
  return (
    <div className="service">
      <div className="service-container">
        <div>
          <h6>
          <a
              href="/1008"
              className="service-link"
            >
              Тээврийн хэрэгслийн бүртгэл
            </a>
          </h6>
          <p>
            Автотээврийн хэрэгслийн бүртгэл хөтлөх,
            <br /> бүртгэл мэдээллийн сан бүрдүүлэх үйл ажиллагааг явуулж байна.
          </p>
          <h6>
          <a
              href="/58"
              className="service-link"
            >
              Тээвэр зохицуулалт
            </a>
          </h6>
          <p>
            Улс хооронд, хот хооронд, орон нутгийн <br />
            нийтийн зорчигч болон ачаа тээврийн үйлчилгээг зохион байгуулж
            байна.
          </p>
          <h6>
            <a
              href="/57"
              className="service-link"
            >
              Техникийн хяналтын үзлэг
            </a>
          </h6>
          <p>
            Техникийн хяналтын үзлэгийг суурин болон <br />
            явуулын 50 төвөөр дамжуулан улсын хэмжээнд зохион байгуулж байна.
          </p>
          <h6>
          <a
              href="/61"
              className="service-link"
            >
              Хөдөлгөөний удирдлага
            </a>
          </h6>
          <p>
            Зорчигч тээврийг 24 цагийн турш хянан зохицуулж,
            <br /> амар тайван зорчих бололцоог бүрдүүлэн ажиллаж байна.
          </p>
        </div>
      </div>
      <div className="service-image d-block">
        <img src={PictureService} alt="zz" />
      </div>
    </div>
  );
}

export default Service;
