import React, { useEffect } from "react";
import "./style.css";
import English from "../../used/image/eng.webp";

const Language = () => {
  useEffect(() => {
    const divToHide = document.querySelector(".kFwPee");
    if (divToHide) {
      divToHide.style.display = "none";
    }
  }, []); 

  return (
    <div className="language">
      <a
        href="https://conventional--gold--porcupine--bettine--poclc-app-mcloud-gov-mn.translate.goog/?_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"
      >
        <img alt="english" src={English} />
        <span>EN</span>
      </a>
    </div>
  );
};

export default Language;
