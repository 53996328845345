import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import "./style.css";
import WidgetContact from "../../WidgetContact";

const AllNews = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.post(
          "article/list",
          { article_type_id: 21, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setNewsItems(response.data.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newsItems.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(newsItems.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="post-main">
      <div className="post-container">
        <div className="text-left">
          <div className="post-content">
            {newsItems.length > 0 && (
              <div className="big-news">
                <div className="blog-big">
                  <img src={newsItems[0].image_url || "https://via.placeholder.com/150"} alt="" className="rounded" />
                  <div className="all-news-warp">
                    <h6 className="h4-md mb-3">{newsItems[0].title}</h6>
                    <div className="date-box">{newsItems[0].created_at}</div>
                    <p>{newsItems[0].description || ""}</p>
                    <div><a href={`/${newsItems[0].id}`}>Дэлгэрэнгүй</a></div>
                  </div>
                </div>
              </div>
            )}
              {currentItems.map((item, index) => (
                <div className="all-news-standard" key={index}>
                  <img src={item.image_url || "https://via.placeholder.com/150"} alt="" className="rounded" />
                  <div className="all-news-warp">
                    <h6 className="h4-md mb-3">{item.title}</h6>
                    <div className="date-box">{item.created_at}</div>
                    <p>{item.description || ""}</p>
                    <div><a href={`/${item.id}`}>Дэлгэрэнгүй</a></div>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ padding: "10px 20px 0px", borderTop: "dotted 1px #CCC" }}></div>
            <ul className="pagination">
              {pageNumbers.map(number => (
                <li key={number} className={number === currentPage ? "active" : ""}>
                  <a onClick={() => paginate(number)} href="#">
                    {number}
                  </a>
                </li>
              ))}
            </ul>
            </div>
          <WidgetContact />
      </div>
    </div>
  );
};

export default AllNews;
