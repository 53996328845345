import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Tabs4Image1 from "../../../../used/image/uliral_page-1.jpg";
import Tabs4Image2 from "../../../../used/image/uliral_page-2.jpg";
import Tabs4Image3 from "../../../../used/image/uliral_page-3.jpg";
import Tabs4Image4 from "../../../../used/image/uliral_page-4.jpg";
import Tabs4Image5 from "../../../../used/image/uliral_page-5.jpg";
import Tabs4Image6 from "../../../../used/image/uliral_page-6.jpg";
import Tabs4Image7 from "../../../../used/image/uliral_page-7.jpg";
import Tabs4Image8 from "../../../../used/image/uliral_page-8.jpg";
import Tabs4Image9 from "../../../../used/image/uliral_page-9.jpg";
import Tabs4Image10 from "../../../../used/image/uliral_page-10.jpg";
import Tabs4Image11 from "../../../../used/image/uliral_page-11.jpg";
import Tabs4Image12 from "../../../../used/image/uliral_page-12.jpg";
import Tabs4Image13 from "../../../../used/image/uliral_page-13.jpg";

const TabsPage4 = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
          <div className="text-left">
            <div className="post-content">
              <div className="meta-box">2024-01-26 06:21:46</div><br/>
              <h6 className="h4-md mb-3 fw-7 txt-blue">
                2023 оны III-р улиралд олгосон хил нэвтрэх С төрлийн зорчих
                зөвшөөрлийн бичгийн зарцуулалт
              </h6>
              <p style={{ textAlign: "center" }}>
                <a href="https://service.transdep.mn/autobox-backend/api/v1//attachment/article/article_59_1_1722420376000.pdf">
                  <strong>Татаж авах| 32 хуудас | 3,1 Мb</strong>
                </a>{" "}
              </p>
              <p>
                <img src={Tabs4Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image3} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image4} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image5} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image6} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image7} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image8} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image9} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image10} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image11} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image12} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
              <p>
                <img src={Tabs4Image13} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
              </p>
            </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default TabsPage4;
