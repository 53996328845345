import { createSlice } from "@reduxjs/toolkit";

export const LoginSlice = createSlice({
  name: "nevtreh",
  initialState: {
    loading: false,
    error: null,
    token: null,
    poster: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUserToken: (state, action) => { 
      state.token = action.payload; 
    },
    setPoster: (state, action) => {
      state.poster = action.payload;
    },
  },
});

export const { setLoading, setError, setUserToken, setPoster } = LoginSlice.actions; 

export default LoginSlice.reducer;
