import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Slide2Image1 from "../../../../used/image/slide 2-1.jpg";
import Slide2Image2 from "../../../../used/image/slide 2-2.jpg";
import Slide2Image3 from "../../../../used/image/slide 2-3.jpg";
import Slide2Image4 from "../../../../used/image/slide 2-4.jpg";

const Slide2Page = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
        <div className="post-content">
          <div className="text-left">
            <div className="meta-box">2023-05-08 04:37:43 </div><br/>
            <h6 className="h4-md mb-3 fw-7 txt-blue">
              Ажлын байран дахь хөдөлмөрийн аюулгүй байдал, эрүүл ахуйн дэлхийн
              өдөр
            </h6>
            <p align="justify" />
            <div dir="auto" style={{ textAlign: "justify" }}>
              <div className="xyamay9 x1pi30zi xsag5q8 x1swvt13">
                <div>
                  <div className="xyinxu5 x4uap5 x1g2khh7 xkhd6sd">
                    <div className="xdj266r x11i5rnm xat24cr x1mh8g0r x1vvkbs x126k92a">
                      <div dir="auto">
                        Олон улсын хөдөлмөрийн байгууллагын шийдвэрээр 2003
                        оноос эхлэн жил бүрийн 4 сарын 28-ныг ажлын байран дахь
                        хөдөлмөрийн аюулгүй байдал, эрүүл ахуйн дэлхийн өдөр
                        болгон тэмдэглэж байгаа билээ.
                      </div>
                      <div dir="auto">&nbsp;</div>
                      <div dir="auto">
                        <img src={Slide2Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
                      </div>
                      <div dir="auto">&nbsp;</div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">
                        Автотээврийн үндэсний төвөөс нэн түрүүнд тээврийн
                        аюулгүй байдал, эрүүл ахуйн соёлыг төлөвшүүлэх, сайн
                        туршлагагүй нэвтрүүлэн солилцох, ажлын байран дахь осол
                        гэмтлийг бууруулах, аюулгүй ажиллагааг хэвшүүлэх, бэртэл
                        гэмтлээс урьдчилан сэргийлэх зорилгоор Дархан-Уул
                        аймгийн Автотээврийн төвд энэхүү өдөрлөгийг зохион
                        байгууллаа.
                      </div>
                      <div dir="auto">&nbsp;</div>
                      <div dir="auto">
                        <img src={Slide2Image2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
                      </div>
                      <div dir="auto">&nbsp;</div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">
                        Тус арга хэмжээнд ЗЦГ-ын Зам тээврийн хяналтын хэлтсийн
                        дарга цагдаагийн хурандаа М. Ариунболд "Авто тээврийн
                        хэрэгслийн техникийн хяналтын үзлэг оношилгоог
                        чанаржуулах" сэдвээр гүйцэтгэлийг сайжруулах асуудал,
                        Замын цагдаагийн тасгийн дарга, цагдаагийн дэд хурандаа
                        Д.Энхтайван "Хөдөлгөөн ашиглалтын журам зөрчих гэмт
                        хэргийн нөхцөл байдал" сэдвээр цаашид анхаарах асуудал,
                        МУИС-ийн сэтгэгдэл судлаач, докторант багш Ц.Нямхүү
                        АТҮТ-өөс МУИС-тай хамтран "Жолоочийн сэтгэл зүй"
                        чиглэлээр хийсэн судалгааны ажлын талаар танилцуулга,
                        Судалгаа, хөгжил, чанарын удирдлагын төвийн чанарын
                        ахлах мэргэжилтэн А.Батчулуун "Замын хөдөлгөөний аюулгүй
                        байдлын удирдлага зохион байгуулалтын тогтолцоо зааврыг
                        хэрэглэх шаардлага MNS ISO 39001:2014 стандарт" сэдвээр,
                        Судалгаа, хөгжил, Чанарын удирдлагын төвийн судалгаа
                        хариуцсан ахлах мэргэжилтэн Ц.Оюунсүрэн АТҮТ-өөс үйл
                        ажиллагаандаа хэрэгжүүлж байгаа "Зөв хөтөлбөр"-ийн
                        талаар тус тус танилцуулга хийж санал солилцлоо.
                      </div>
                      <div dir="auto">&nbsp;</div>
                      <div dir="auto">
                        <img src={Slide2Image3} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
                      </div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">
                        Өдөрлөгт зорчигч тээврийн төвүүд, Дархан, Орхон,
                        Сэлэнгэ, Булган, Хөвсгөл, Төв аймгийн автотээврийн
                        салбарууд хот хоорондын зорчигч тээврийн болон авто
                        үйлчилгээ эрхлэгч байгууллага, Дархан-Уул аймгийн онцгой
                        байдлын газар оролцов.
                      </div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">
                        "Чимээгүй" гавъяатан гэгдэх Хөдөлмөрийн аюулгүй байдлыг
                        хариуцан ажилладаг нийт хүмүүстээ талархал илэрхийлье.
                      </div>
                      <div dir="auto">&nbsp;</div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">АЮУЛГҮЙ АЖИЛЛАГАА АМЬДРАЛЫН БАТАЛГАА</div>
                    </div>
                    <div className="x11i5rnm xat24cr x1mh8g0r x1vvkbs xtlvy1s x126k92a">
                      <div dir="auto">
                        Та бүхний алтан шар зам тань өлзийтэй байх болтугай
                      </div>
                    </div>
                  </div>
                </div>
                <div className="x78zum5">&nbsp;</div>
                <div className="x78zum5">
                  <img src={Slide2Image4} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
                </div>
              </div>
              <div>
                <div>
                  <div className=" x3dsekl x1uuop16">
                    <div className="x9f619 x1n2onr6 x1ja2u2z x78zum5 x2lah0s x1qughib x1qjc9v5 xozqiw3 x1q0g3np xjkvuk6 x1iorvi4 x4cne27 xifccgj">
                      <div className="x9f619 x1n2onr6 x1ja2u2z x78zum5 xdt5ytf x193iq5w xeuugli x1r8uery x1iyjqo2 xs83m0k x10b6aqq x1yrsyyn">
                        <div
                          className="x1i10hfl x1qjc9v5 xjbqb8w xjqpnuy xa49m3k xqeqjp1 x2hbi6w x13fuv20 xu3j5b3 x1q0q8m5 x26u7qi x972fbf xcfux6l x1qhh985 xm0m39n x9f619 x1ypdohk xdl72j9 x2lah0s xe8uvvx xdj266r x11i5rnm xat24cr x1mh8g0r x2lwn1j xeuugli xexx8yu x4uap5 x18d9i69 xkhd6sd x1n2onr6 x16tdsg8 x1hl2dhg xggy1nq x1ja2u2z x1t137rt x1o1ewxj x3x9cwd x1e5q0jg x13rtm0m x3nfvp2 x1q0g3np x87ps6o x1lku1pv x1a2a7pz"
                          tabIndex={0}
                        >
                          <div className="x9f619 x1n2onr6 x1ja2u2z x78zum5 x1r8uery x1iyjqo2 xs83m0k xeuugli xl56j7k x6s0dn4 xozqiw3 x1q0g3np xn6708d x1ye3gou xexx8yu xcud41i x139jcc6 x4cne27 xifccgj xn3w4p2 xuxw1ft">
                            &nbsp;
                          </div>
                          <div
                            className="x1o1ewxj x3x9cwd x1e5q0jg x13rtm0m x1ey2m1c xds687c xg01cxk x47corl x10l6tqk x17qophe x13vifvy x1ebt8du x19991ni x1dhq9h"
                            data-visualcompletion="ignore"
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div dir="auto" style={{ textAlign: "justify" }}>
              <br />
            </div>
            <p />
          </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Slide2Page;
