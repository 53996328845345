// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-results {
    padding: 20px;
    min-height: 400px;
  }

  .search-wrapper {
    width: 300px;
  }
  
  .result-list {
    display: flex;
    flex-direction: column;
  }
  
  .result-item {
    margin-left: 110px;
    border: 1px solid #ddd;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  .result-item a {
    display: flex;
    align-items: center;
  }

  
  .result-image {
    width: 200px;
    min-width: 200px;
    height: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    margin-right: 16px;
  }
  
  .result-content {
    flex: 1 1;
  }
  
  .result-title {
    font-size: 15px;
    margin: 0;
    color: #333;
  }
  
  .result-date {
    color: #666;
    font-size: 0.9em;
  }
  
  @media (max-width: 700px) {
    .result-item {
      margin-left: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/ZPage/SearchPage/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;;EAGA;IACE,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,eAAe;IACf,SAAS;IACT,WAAW;EACb;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE;MACE,gBAAgB;IAClB;AACJ","sourcesContent":[".search-results {\n    padding: 20px;\n    min-height: 400px;\n  }\n\n  .search-wrapper {\n    width: 300px;\n  }\n  \n  .result-list {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .result-item {\n    margin-left: 110px;\n    border: 1px solid #ddd;\n    padding: 16px;\n    margin-bottom: 16px;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  }\n\n  .result-item a {\n    display: flex;\n    align-items: center;\n  }\n\n  \n  .result-image {\n    width: 200px;\n    min-width: 200px;\n    height: 150px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);\n    margin-right: 16px;\n  }\n  \n  .result-content {\n    flex: 1;\n  }\n  \n  .result-title {\n    font-size: 15px;\n    margin: 0;\n    color: #333;\n  }\n  \n  .result-date {\n    color: #666;\n    font-size: 0.9em;\n  }\n  \n  @media (max-width: 700px) {\n    .result-item {\n      margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
