import React from "react";
import "./style.css";
import WidgetContact from "../../WidgetContact";

const ServicePage = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
          <div className="text-left">
          <div className="post-content">
            <div className="meta-box">2021-10-25 01:31:45 </div>
            <h6 className="h4-md mb-3 fw-7 txt-blue">
              Тээврийн хэрэгслийн бүртгэл, шилжилт хөдөлгөөн
            </h6>
            <p align="justify" />
            <p align="left">
              Зам, тээврийн хөгжлийн сайдын 2020.11.18- ны өдрийн А/222 дугаар
              тушаалаар батлагдсан “Автотээврийн хэрэгслийн бүртгэл хөтлөх,
              улсын дугаар олгох журам”-г мөрдөн улсын хэмжээнд автотээврийн
              хэрэгслийн бүртгэл хөтлөх, бүртгэл мэдээллийн сан бүрдүүлэх үйл
              ажиллагааг явуулж байна.
            </p>
            <p align="left">
              Автотээврийн хэрэгслийн бүртгэл нь дараах төрөлтэй байна.
            </p>
            <ul>
              <li>
                Автотээврийн хэрэгслийг шинээр бүртгэх; /журмын 3-т заасныг
                харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автотээврийн хэрэгслийн шилжилт хөдөлгөөний бүртгэл; /журмын 4-т
                заасныг харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автотээврийн хэрэгслийн техникийн өөрчлөлт; /журмын 5-д заасныг
                харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автотээврийн хэрэгслийн улсын бүртгэлийн дугаар, гэрчилгээг
                нөхөн олгох; /журмын 6-д заасныг харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автотээврийн хэрэгслийн бүртгэлээс хасах, бүртгэлийг сэргээх;
                /журмын 7-д заасныг харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автотээврийн хэрэгсэлд түр дугаар олгох; /журмын 8-д заасныг
                харах{" "}
                <a
                  href="https://legalinfo.mn/main/detail/15748"
                  target="_blank"
                >
                  <span style={{ color: "#3366ff" }}>
                    https://legalinfo.mn/main/detail/15748
                  </span>
                  <br />
                  <br />
                </a>
              </li>
              <li>
                Автохэрэгслийн улсын бүртгэлийн дугаарын өөрчлөлт, солилт
                /журмын 9-д заасныг харах{" "}
                <span style={{ color: "#3366ff" }}>
                  <a
                    style={{ color: "#3366ff" }}
                    href="https://legalinfo.mn/main/detail/15748"
                    target="_blank"
                  >
                    https://legalinfo.mn/main/detail/15748
                  </a>
                </span>
              </li>
            </ul>
            <p />
            </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
