import React, { useState, useEffect } from 'react';
import axios from '../../../axios';
import "./style.css";
import WidgetContact from "../../WidgetContact";

const Holboo = () => {
  const token = localStorage.getItem('token');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'article/data',
          { id: 13 },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }
        );
        setData(response.data.data);
      } catch (error) {
        setError(error);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className='post-main'>
        <div className="post-content">
      <div className='holboo-page'>
      <div>
        <div className="holboo-content" dangerouslySetInnerHTML={{ __html: data.content }} /></div>
        <div className="holboo-text">
          <h6 className="holboo-title">Холбоо барих</h6>
          
            <p>
              <strong>Хаяг:</strong> <br />
              Чингисийн өргөн чөлөө 11, Автотээврийн Үндэсний Төв
              <br />
              <strong>Утас: </strong>
              <br />
              1900-1234
              <br />
              <strong>
                Өргөдөл, гомдол хүлээн авах утас:
                <br />
              </strong>
              70071234
              </p>
            <p>
              <strong>И-мэйл: </strong>
              <br />
              <a href="mailto:info@transdep.mn">info@transdep.mn </a>
            </p>
            <p>
              <strong>Фэйсбүүк хуудас:</strong>
              <br />
              <a
                href="https://www.facebook.com/autoteever"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/autoteever
              </a>
              <br /><strong>Твиттер хуудас:</strong>
              <br />
              <a
                href="https://twitter.com/atutuv"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/atutuv
              </a>
              <br />
              <strong>Инстаграм хуудас:</strong>
              <br />
              <a
                href="https://www.facebook.com/autoteever"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.instagram.com/atutuv
              </a>
            </p>
          <div className="contact-social">
            <div className="social-item">
              <a
                href="https://www.facebook.com/autoteever"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="25"
                  height="25"
                  fill="steelBlue"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.253.621-1.253 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                </svg>
              </a>
            </div>
            <div className="social-item">
              <a
                href="https://twitter.com/atutuv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="25" height="25" fill="blue" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                </svg>
              </a>
            </div>
            <div className="social-item">
              <a
                href="https://www.youtube.com/channel/UCWQinNziRdG7n_wqlqDmlIg/videos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="25" height="25" fill="red" viewBox="0 0 16 16">
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.259 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                </svg>
              </a>
            </div>
            </div>
            </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
  );
}
};

export default Holboo;
