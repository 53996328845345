// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
  }
  
  .home h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.1;
  }
  
  .home a {
    text-decoration: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/Home/style.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,gBAAgB;EAClB;;EAEA;;;;;;IAME,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".home {\n    font-family: \"Roboto Condensed\", sans-serif;\n    font-weight: 300;\n  }\n  \n  .home h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    text-transform: uppercase;\n    font-weight: 400;\n    line-height: 1.1;\n  }\n  \n  .home a {\n    text-decoration: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
