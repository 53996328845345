// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poster {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 50px 300px;
}

.poster p {
text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/pages/Batalgaajuulalt/ErhzuinMedeelel/Geree/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;AACA,mBAAmB;AACnB","sourcesContent":[".poster {\n    display: flex;\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    padding: 50px 300px;\n}\n\n.poster p {\ntext-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
