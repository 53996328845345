import React from "react";
import "./style.css";

function Footer({ onLinkClick }) {
  const handleLinkClick = () => {
    if (onLinkClick) {
      onLinkClick();
    }
  };
  return (
    <div className="copyright-wrap bg-navy-blue wide-tb-30">
      <div className="footer-container">
      <div className="container">
        <div className="row text-md-start">
          <div className="footer-text col-sm-12 col-md-6">
            <a href="/">Нүүр хуудас</a>{" "}
            <span>|</span>{" "}
            <a href="/1004">
              Салбаруудын байршил
            </a>{" "}
            <span>|</span>{" "}
            <a href="/1005">Холбоо барих</a>{" "}
          </div>
          <div className="footer-text col-sm-12 col-md-6 text-md-end">
            <a href="#" onClick={handleLinkClick}>
              Автотээврийн Үндэсний Төв ТӨҮГ
            </a>{" "}
            © 2024
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Footer;
