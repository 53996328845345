import React from 'react';
import Header from './Header';
import Menu from './Menu';
import Content from './Content';
import Login from './Login';

export default function Admin() {
  return (
    <div>
      <Login />
      <Header />
      <Content />
    </div>
  );
}
