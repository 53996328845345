import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Tabs1Image1 from "../../../../used/image/urilga1.jpg";

const TabsPage1 = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
          <div className="text-left">
            <div className="post-content">
            <div className="meta-box">2023-09-19 06:05:52</div><br/>
            <h6 className="h4-md mb-3 fw-7 txt-blue">
              ТЕНДЕРИЙН УРИЛГА - 2023.09.25
            </h6>
            <p align="justify" />
            <p>
              <img src={Tabs1Image1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
            </p>
            <p />
            </div>
          </div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default TabsPage1;
