// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-main {
  display: flex;
  text-align: justify;
  align-items: center;
  justify-content: center;

  margin: 60px 140px 60px 140px;
  font-size: 15px;
}

.post-container {
  width: 1400px;
  display: flex;
}

.post-title {
  padding-left: 20px;
  color: #fff;
  background: rgba(10, 36, 88, 0.99);
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.post-content {
  min-width: 1000px;
  padding: 30px;
  border-right: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

td {
  border: 1px solid #ddd;
  padding: 8px;
}

tr:nth-child(odd) {
  background-color: #dce7fc;
}

tr:hover {
  background-color: #0a2458;
  color: #ffffff;
}

@media (max-width: 1000px) {
  .post-main {
    position: relative;
    width: 100%;
    left: -140px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Bidnii_tuhai/Taniltsuulga/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;;EAEvB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,4BAA4B;EAC5B,wCAAwC;AAC1C;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".post-main {\n  display: flex;\n  text-align: justify;\n  align-items: center;\n  justify-content: center;\n\n  margin: 60px 140px 60px 140px;\n  font-size: 15px;\n}\n\n.post-container {\n  width: 1400px;\n  display: flex;\n}\n\n.post-title {\n  padding-left: 20px;\n  color: #fff;\n  background: rgba(10, 36, 88, 0.99);\n  height: 45px;\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n}\n\n.post-content {\n  min-width: 1000px;\n  padding: 30px;\n  border-right: 1px solid #ddd;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);\n}\n\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n}\n\ntr:nth-child(odd) {\n  background-color: #dce7fc;\n}\n\ntr:hover {\n  background-color: #0a2458;\n  color: #ffffff;\n}\n\n@media (max-width: 1000px) {\n  .post-main {\n    position: relative;\n    width: 100%;\n    left: -140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
