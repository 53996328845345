// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.search-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    margin: 0 auto;
  }
  
  .search-input {
    width: 100%;
    border: 1px solid #ccc;
    outline: none;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #2186d9;
  }
  
  .search-button {
    padding: 0 10px;
    color: #000;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #0a2458;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Search/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,eAAe;IACf,kCAAkC;EACpC;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.search-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #f5f5f5;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    max-width: 200px;\n    margin: 0 auto;\n  }\n  \n  .search-input {\n    width: 100%;\n    border: 1px solid #ccc;\n    outline: none;\n    font-size: 14px;\n    transition: border-color 0.3s ease;\n  }\n  \n  .search-input:focus {\n    border-color: #2186d9;\n  }\n  \n  .search-button {\n    padding: 0 10px;\n    color: #000;\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .search-button:hover {\n    background-color: #0a2458;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
