import React from "react";
import "./style.css";
import WidgetContact from "../../../WidgetContact";
import Tabs6Image1 from "../../../../used/image/zamiinuud2.jpg";

const TabsPage6 = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div>
          <div className="post-content">
          <div className="text-left">
            <div className="meta-box">2024-07-30 04:48:56</div><br/>
            <h6 className="h4-md mb-3 fw-7 txt-blue">АЖЛЫН БАЙРНЫ ЗАР 2024</h6>
            <p align="justify">
              <img src={Tabs6Image1} alt="" style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} />
            </p>
          </div></div>
        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default TabsPage6;
