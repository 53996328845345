import React, { useState, useEffect } from "react";
import axios from "../../axios";
import Mail from "./Mail";
import Editor from "./Editor/Editor";
import AdminSearch from "../../pages/Admin/AdminSearch";

const categoryNames = {
  5: "БИДНИЙ ТУХАЙ",
  6: "Бүтцийн нэгжүүд",
  7: "ХУУЛЬ, ЭРХ ЗҮЙ",
  8: "БАТАЛГААЖУУЛАЛТ",
  9: "Бидний тухай",
  10: "Эрхзүйн мэдээлэл",
  11: "Автотээврийн хэрэгслийн засвар үйлчилгээний баталгаажуулалт",
  12: "Нийтийн зорчигч тээврийн үйлчилгээний баталгаажуулалт",
  13: "Ачаа тээврийн үйлчилгээний баталгаажуулалт",
  14: "ҮЙЛЧИЛГЭЭ",
  15: "Тээврийн хэрэгсэлд тавигдах шаардлага",
  16: "ИЛ ТОД БАЙДАЛ",
  17: "Үйл ажиллагаа",
  18: "Худалдан авах ажиллагаа",
  19: "Хүний нөөц",
  20: "Тээвэр зохицуулалт",
  21: "МЭДЭЭ МЭДЭЭЛЭЛ",
  22: "Ажлын байр",
  23: "Төсөв санхүү",
  24: "Олгосон зөвшөөрлийн мэдээлэл", 
  25: "2016",
  26: "2017",
  27: "2018",
  28: "2019",
  29: "2020",
  30: "2021",
  31: "2022",
  32: "2023",
};

const getCategoryName = (article_type_id) => categoryNames[article_type_id] || null;

const getCategoryId = (categoryName) => {
  const entry = Object.entries(categoryNames).find(([id, name]) => name === categoryName);
  return entry ? parseInt(entry[0]) : null;
};

const Content = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [post1, setPost1] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("БИДНИЙ ТУХАЙ");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemId, setItemId] = useState(null);

  const handleEditClick = (itemId) => {
    setItemId(itemId);
  };

  const handleAddClick = async () => {
    const article_type_id = getCategoryId(selectedCategory);
    if (!article_type_id) {
      console.error("Invalid category selected");
      return;
    }

    const newItem = {
      id: 0,
      title: "New Item",
      article_type_id: article_type_id,
    };

    try {
      const response = await axios.post("article/edit", newItem, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const addedItem = response.data.data;
      addedItem.category = selectedCategory;

      setPost1((prevPosts) => [...prevPosts, addedItem]);
    } catch (error) {
      setError(error);
    }
  };

  const handleDeleteClick = async (itemId) => {
    const isConfirmed = window.confirm("Устгахдаа итгэлтэй байна уу?");
    if (!isConfirmed) {
      return;
    }

    try {
      await axios.post(
        "article/delete",
        { id: itemId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPost1((prevPosts) => prevPosts.filter((post) => post.id !== itemId));
    } catch (error) {
      setError(error);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = [];
        for (let article_type_id = 5; article_type_id <= 32; article_type_id++) {
          requests.push(
            axios.post(
              "article/list",
              { article_type_id, limit: 100 },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
          );
        }

        const responses = await Promise.all(requests);
        const fetchedData = responses.flatMap((response) => response.data.data);

        setData(fetchedData);
        const updatedPosts = fetchedData.map((data) => ({
          category: getCategoryName(data.article_type_id),
          title: data.title || "",
          id: data.id,
        }));

        setPost1(updatedPosts);
      } catch (error) {
        setError(error);
      }
    };

    if (token) {
      fetchData();
    }
  }, [token]);

  const filteredPosts = post1.filter((post) =>
    (selectedCategory === "all" || post.category === selectedCategory) &&
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!data.length && !post1.length) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Хяналтын самбар</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header border-0">
                    <h3 className="card-title">Pages</h3>
                    <div className="card-tools">
                      <a href="#" className="text-muted" onClick={handleAddClick}>
                        <i className="fas fa-plus" />&nbsp;Шинэ пост үүсгэх
                      </a>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="mb-3">
                      <label htmlFor="categorySelect" className="form-label">
                        Категори сонгоx:
                      </label>
                      <select
                        className="form-select"
                        id="categorySelect"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        {Object.values(categoryNames).map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                        <option value="all">All Categories</option>
                      </select>
                    </div>
                    <AdminSearch searchQuery={searchQuery} onSearchChange={handleSearchChange} />
                    <table className="table table-striped table-valign-middle">
                      <thead>
                        <tr>
                          <th>MAIN MENU</th>
                          <th>SUB MENU</th>
                          <th>ID</th>
                          <th>EDIT</th>
                          <th>DELETE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPosts.map((v) => (
                          <tr key={v.id}>
                            <td>{v.category}</td>
                            <td>{v.title}</td>
                            <td>{v.id}</td>
                            <td>
                              <a
                                href="#"
                                className="text-muted"
                                onClick={() => handleEditClick(v.id)}
                              >
                                <i className="fas fa-edit" />
                              </a>
                            </td>
                            <td>
                              <a
                                href="#"
                                className="text-muted"
                                onClick={() => handleDeleteClick(v.id)}
                              >
                                <i className="fas fa-trash" />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <Editor itemId={itemId} />
              </div>
              <Mail/>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Content;
