import React, { useState, useEffect } from "react";
import axios from "../../../axios";
import { useLocation } from "react-router-dom";
import "./style.css";

const SearchPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const token = localStorage.getItem("token");

  const searchQuery = new URLSearchParams(location.search).get("query") || "";

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.post(
          "article/list",
          { article_type_id: 21, limit: 100 },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredResults = response.data.data.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        console.log(filteredResults);

        setSearchResults(filteredResults);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    if (token && searchQuery) {
      fetchSearchResults();
    }
  }, [searchQuery, token]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="search-results">
      {searchResults.length ? (
        <div className="result-list">
          {searchResults.map((result) => {
            const imageUrl = result.image_url 
              ? result.image_url 
              : "https://via.placeholder.com/150";
            
            console.log('Image URL:', imageUrl);

            return (
              <div className="result-item" key={result.id}>
                <a href={`/${result.id}`}>
                <img
                  src={imageUrl}
                  alt={result.title}
                  className="result-image"
                />
                <div className="result-content">
                  <p className="result-date">{formatDate(result.created_at)}</p>
                  <strong><h6 className="result-title">{result.title}</h6></strong>
                </div>
                </a>
              </div>
             
            );
          })}
        </div>
      ) : (
        <p>"{searchQuery}" утгаар xайсан мэдээлэл олдсонгүй.</p>
      )}
    </div>
  );
};

export default SearchPage;
