import React, { useEffect, useState } from "react";
import axios from '../../axios';

const Mail = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const token = localStorage.getItem("token");

  const fetchMessages = async () => {
    try {
      const response = await axios.post('/grievance/list', {}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      setMessages(response.data.data); 
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching the messages!", error);
      setError("Failed to fetch messages.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleDelete = async (messageId) => {
    try {
      const response = await axios.post('/grievance/delete', { id: messageId }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.status === 200) {
        setMessages(messages.filter(message => message.id !== messageId));
        setDeleteError(null);
      } else {
        console.error("Delete response status:", response.status);
        setDeleteError("Failed to delete messages. Please try again.");
      }
    } catch (error) {
      console.error("Error details:", error.response ? error.response.data : error.message);
      setDeleteError("Failed to delete messages. Please try again.");
    }
  };

  const totalMessages = messages.length;
  const totalPages = Math.ceil(totalMessages / itemsPerPage);

  const getPaginatedMessages = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return messages.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const paginatedMessages = getPaginatedMessages();

  return (
    <div className="card card-primary card-outline">
      <div className="card-header">
        <h3 className="card-title"><strong>САНАЛ ХҮСЭЛТ</strong></h3>
      </div>
      <div className="card-body p-0">
        <div className="mailbox-controls">
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              &nbsp;<i className="fas fa-angle-left" />&nbsp;Өмнөх&nbsp;
            </button>
          )}
          <span>&nbsp;Page {currentPage} of {totalPages}&nbsp;</span>
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
             &nbsp;Дарааx&nbsp;<i className="fas fa-angle-right" />&nbsp;
            </button>
          )}
        </div>
        {deleteError && <div className="alert alert-danger">{deleteError}</div>}
        <div className="table-responsive mailbox-notes">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>Огноо</th>
                <th></th>
                <th></th>
                <th>Нэр</th>
                <th>Санал хүсэлт</th>
                <th>Утасны дугаар</th>
              </tr>
            </thead>
            <tbody>
              {paginatedMessages.map((message) => (
                <tr key={message.id}>
                  <td className="mailbox-date">{new Date(message.created_at).toLocaleDateString()}</td>
                  <td>
                    <div className="icheck-primary">
                      <input
                        type="checkbox"
                        id={`check${message.id}`}
                      />
                      <label htmlFor={`check${message.id}`} />
                    </div>
                  </td>
                  <td className="mailbox-trash">
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      handleDelete(message.id);
                    }}>
                      <i className="fas fa-trash" />
                    </a>
                  </td>
                  <td className="mailbox-name">
                    <a href="read-mail.html">{message.customer_name}</a>
                  </td>
                  <td className="mailbox-subject">
                    <b>{message.email}</b> - {message.note}
                  </td>
                  <td className="mailbox-phone">{message.phone_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer p-0">
      <div className="mailbox-controls">
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              &nbsp;<i className="fas fa-angle-left" />&nbsp;Өмнөх&nbsp;
            </button>
          )}
          <span>&nbsp;Page {currentPage} of {totalPages}&nbsp;</span>
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
             &nbsp;Дарааx&nbsp;<i className="fas fa-angle-right" />&nbsp;
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Mail;
