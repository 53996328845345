import React from "react";
import "./style.css";
import Slide from "../Slide";
import News from "../News";
import Service from "../Service";
import Infograph from "../Infograph";
import Online from "../Online";
import { Tabs } from "../Tabs";


function Home () {
  return ( 
    <div className="home">
      <Slide />
      <News />
      <Online />
      <Infograph />
      <Service />
      <Tabs />
      </div>
        
  )
};

export default Home;
