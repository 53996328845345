import React from "react";
import "./style.css";
import WidgetContact from "../../WidgetContact";
import Bairshil1 from "../../../used/image/bairshil 1.jpg";
import Bairshil2 from "../../../used/image/bairshil 2.jpg";
const Bairshil = () => {
  return (
    <div className="post-main">
      <div className="post-container">
        <div className="post-content">
        <div className="text-left">
  <div className="meta-box">2016-05-30 04:41:05</div><br/>
  <h6 className="h4-md mb-3 fw-7 txt-blue">Салбаруудын байршил</h6>
  <p align="justify" /><p><strong>Улаанбаатар хотын Автотээврийн төвүүд:</strong></p>
  <p><strong><img src={Bairshil1} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></strong></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong><img src={Bairshil2} style={{ maxWidth: '1000px', width: '100%', height: 'auto' }} /></strong></p>
  <p><strong><br /> Орон нутаг дахь Автотээврийн төвүүд:</strong></p>
  <table style={{borderCollapse: 'collapse'}} border={1} cellPadding={2}>
    <tbody>
      <tr>
        <td align="center" bgcolor="#F0F0F0">
          <p align="center"><strong>No.</strong></p>
        </td>
        <td bgcolor="#F0F0F0">
          <p align="center"><strong>Аймаг нэр</strong></p>
        </td>
        <td bgcolor="#F0F0F0">
          <p align="center"><strong>Байрлал</strong></p>
        </td>
        <td nowrap="nowrap" bgcolor="#F0F0F0">
          <p align="center"><strong>Цагийн хуваарь</strong></p>
        </td>
      </tr>
      <tr>
        <td align="center">1.</td>
        <td><strong>Архангай аймаг</strong></td>
        <td>Эрдэнэбулган сум, 7-р баг</td>
        <td nowrap="nowrap">
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">2.</td>
        <td><strong>Баян-Өлгий аймаг</strong></td>
        <td>Өлгий сум 13-р баг</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">3.</td>
        <td><strong>Баянхонгор аймаг</strong></td>
        <td>Номгон 1-р баг</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">4.</td>
        <td><strong>Булган аймаг</strong></td>
        <td>Булган сум, 5-р баг</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">5.</td>
        <td><strong>Говь-Алтай аймаг</strong></td>
        <td>Есөн булаг сум, Жаргалант баг 1-7</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">6.</td>
        <td><strong>Говьсүмбэр аймаг</strong></td>
        <td>Сүмбэр сум 3-р баг, Петротрак ХХК-ны байранд</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">7.</td>
        <td><strong>Дархан-Уул аймаг</strong></td>
        <td>Дархан сум 14-р баг, Дархан-Уул аймгийн Авто Тээврийн Төв</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">8.</td>
        <td><strong>Дорноговь аймаг</strong></td>
        <td>Сайншанд сум, 3-р баг, Алтанговийн гудамж</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">9.</td>
        <td><strong>Дорнод аймаг</strong></td>
        <td>Хэрлэн сум 6-р баг</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">10.</td>
        <td><strong>Дундговь аймаг</strong></td>
        <td>Сайнцагаан сум, 7-р баг залуучуудын гудамж</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">11.</td>
        <td><strong>Завхан аймаг</strong></td>
        <td>Улиастай сум, Өлзийт баг</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">12.</td>
        <td><strong>Замын-Үүд</strong></td>
        <td>Замын-Үүд сум 2-р баг, Автотээврийн төв</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">13.</td>
        <td><strong>Орхон аймаг</strong></td>
        <td>Орхон Баян-Өндөр сум, Баянцагаан баг, Дулааны цахилгаан станцын баруун талд</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">14.</td>
        <td><strong>Өвөрхангай аймаг</strong></td>
        <td>Арвайхээр сум, 5-р баг хангамжийн хашаа</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">15.</td>
        <td><strong>Өмнөговь аймаг</strong></td>
        <td>Даланзадгад сум 6-р баг Их-уул</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">16.</td>
        <td><strong>Сүхбаатар аймаг</strong></td>
        <td>Баруун-Урт сум 2-р баг Оношлогооны байр</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">17.</td>
        <td><strong>Сэлэнгэ аймаг</strong></td>
        <td>Сүхбаатар сум, 4-р баг, 8-р хэсэг</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">18.</td>
        <td><strong>Төв аймаг</strong></td>
        <td>Зуунмод сум 5-р баг УАА төв АЗЗА ТӨХК - н байр</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">19.</td>
        <td><strong>Увс аймаг</strong></td>
        <td>Улаангом сум 5-р баг, Автотээврийн төв</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">20.</td>
        <td><strong>Ховд аймаг</strong></td>
        <td>Жаргалант сум, алагтолгой баг төв захын хажууд</td>
        <td>
          <p align="center">08:00 – 17:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">21.</td>
        <td><strong>Хөвсгөл аймаг </strong></td>
        <td>Мөрөн сум, 7-р баг, Эрчим хүчний ард Авто Тээврийн Төвийн байр</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">22.</td>
        <td><strong>Хэнтий аймаг</strong></td>
        <td>Хэрлэн сум 4-р баг Монголын Цахилгаан Холбооны Сүлжээ компанийн байр</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
      <tr>
        <td align="center">23.</td>
        <td><strong>Өмнөговь аймаг</strong></td>
        <td>Цогтцэций сумын Цагаан овоо 2-р баг</td>
        <td>
          <p align="center">09:00 – 18:00</p>
        </td>
      </tr>
    </tbody>
  </table>
  <p>&nbsp;</p><p />
</div>

        </div>
        <div>
          <WidgetContact />
        </div>
      </div>
    </div>
  );
};

export default Bairshil;
